import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GenericObject } from '@utils/models/Types';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TbDistributorsConstant } from '@pages/task-board/constants/tb-distributors.constant';
import { DistributorManagerFilterProps } from '@pages/distributor/enums/distributor-manager.enum';
import { TbDistributorFilterProps } from '@pages/task-board/enums/tb-distributors.enum';
import { DropdownList } from '@components/atoms/form-inputs/components/next-dropdown/types/dropdown.type';
import { Observable } from 'rxjs';
import { FormDataConstant } from '@components/atoms/form-inputs/constants/form-data.constant';
import { DropdownService } from '@components/atoms/form-inputs/components/next-dropdown/services/dropdown.service';
import { TaskBoardEndpoints } from '@pages/task-board/constants/task-board.endpoints.constant';
import { NextFormHelper } from '@utils/core/next-form.helper';
import { MaintenanceTaskProps } from '@pages/task-board/enums/task-board.enum';

@Component({
  selector: 'tb-distributors-filter',
  templateUrl: './tb-distributors-filter.component.html',
  styleUrl: './tb-distributors-filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TbDistributorsFilterComponent implements OnInit {
  @Input() filterValues: GenericObject = {};
  @Input() hasExtraFilter: boolean = false;
  @Output() filterChange = new EventEmitter<any>();

  extraFormGroup: FormGroup;
  requestTypeList$: Observable<DropdownList>;
  protected readonly TbDistributorsConstant = TbDistributorsConstant;
  protected readonly DistributorManagerFilterProps = DistributorManagerFilterProps;
  protected readonly TbDistributorFilterProps = TbDistributorFilterProps;

  constructor(
    private _fb: FormBuilder,
    private _dropdownService: DropdownService
  ) {}

  ngOnInit(): void {
    this._initializeFormConfig();
    this._defineFormGroup();
  }

  private _initializeFormConfig() {
    this.requestTypeList$ = this._dropdownService.getDropdownTranslationList(
      TaskBoardEndpoints.config.REQUEST_TYPE_LIST,
      { ...FormDataConstant.dropdownBaseConfig },
      { mockResponse: true }
    );
  }

  private _defineFormGroup() {
    const groupValues = NextFormHelper.buildGroupValues(
      [
        TbDistributorFilterProps.REQUEST_TYPE,
        TbDistributorFilterProps.CREATION_DATE_FROM,
        TbDistributorFilterProps.CREATION_DATE_TO
      ],
      {
        ...TbDistributorsConstant.extraFilterInitialValues,
        ...this.filterValues
      }
    );
    this.extraFormGroup = this._fb.group(groupValues);
  }

  protected readonly MaintenanceTaskProps = MaintenanceTaskProps;
}
