import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DropdownService } from '@components/atoms/form-inputs/components/next-dropdown/services/dropdown.service';
import { DropdownList } from '@components/atoms/form-inputs/components/next-dropdown/types/dropdown.type';
import { FormDataConstant } from '@components/atoms/form-inputs/constants/form-data.constant';
import { TaskBoardEndpoints } from '@pages/task-board/constants/task-board.endpoints.constant';
import { TbGeneralMaintenanceConstant } from '@pages/task-board/constants/tb-general-maintenance.constant';
import { TbGeneralMaintenanceFilterProps, TbGeneralMaintenanceProps } from '@pages/task-board/enums/tb-general-maintenance.enum';
import { NextFormHelper } from '@utils/core/next-form.helper';
import { GenericObject } from '@utils/models/Types';
import { Observable } from 'rxjs';

@Component({
  selector: 'tb-general-maintenance-filter',
  templateUrl: './tb-general-maintenance-filter.component.html',
  styleUrl: './tb-general-maintenance-filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TbGeneralMaintenanceFilterComponent  implements OnInit {
  @Input() filterValues: GenericObject = {};
  @Input() hasExtraFilter: boolean = false;
  @Output() filterChange = new EventEmitter<any>();

  extraFormGroup: FormGroup;
  requestTypeList$: Observable<DropdownList>;
  protected readonly TbGeneralMaintenanceProps = TbGeneralMaintenanceProps;
  protected readonly TbGeneralMaintenanceConstant = TbGeneralMaintenanceConstant;
  protected readonly TbGeneralMaintenanceFilterProps = TbGeneralMaintenanceFilterProps;

  constructor(
    private _fb: FormBuilder,
    private _dropdownService: DropdownService
  ) {}

  ngOnInit(): void {
    this._initializeFormConfig();
    this._defineFormGroup();
  }

  private _initializeFormConfig() {
    this.requestTypeList$ = this._dropdownService.getDropdownTranslationList(
      TaskBoardEndpoints.config.REQUEST_TYPE_GM_LIST,
      { ...FormDataConstant.dropdownBaseConfig },
      { mockResponse: true }
    );
  }

  private _defineFormGroup() {
    const groupValues = NextFormHelper.buildGroupValues(
      [
        TbGeneralMaintenanceFilterProps.REQUEST_TYPE,
        TbGeneralMaintenanceFilterProps.CREATION_DATE_FROM,
        TbGeneralMaintenanceFilterProps.CREATION_DATE_TO
      ],
      {
        ...TbGeneralMaintenanceConstant.extraFilterInitialValues,
        ...this.filterValues
      }
    );
    this.extraFormGroup = this._fb.group(groupValues);
  }
}
