import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DropdownService } from '@components/atoms/form-inputs/components/next-dropdown/services/dropdown.service';
import { TbVehicleOrderConstant } from '@pages/task-board/constants/tb-vehicle-order.constant';
import { TbVehicleOrderFilterProps, TbVehicleOrderProps } from '@pages/task-board/enums/tb-vehicle-order.enum';
import { NextFormHelper } from '@utils/core/next-form.helper';
import { GenericObject } from '@utils/models/Types';

@Component({
  selector: 'tb-vehicle-order-filter',
  templateUrl: './tb-vehicle-order-filter.component.html',
  styleUrl: './tb-vehicle-order-filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TbVehicleOrderFilterComponent implements OnInit {
  @Input() vehicleOrderList: any;
  @Input() filterValues: GenericObject = {};
  @Input() selection: any[];
  @Input() hasExtraFilter: boolean = false;
  @Output() filterChange = new EventEmitter<any>();
  @Output() modifyAction = new EventEmitter<any>();
  @Output() cancelAction = new EventEmitter<any>();
  @Output() approveAction = new EventEmitter<any>();

  extraFormGroup: FormGroup;
  distributorDropdown: any;
  sourceLocationDropdown: any;
  orderNumberDropdown: any;
  accountStatusDropdown: any;
  operationalStatusDropdown: any;
  riskClassDrpdown: any;

  protected readonly TbVehicleOrderConstant = TbVehicleOrderConstant;
  protected readonly TbVehicleOrderFilterProps =  TbVehicleOrderFilterProps;
  protected readonly TbVehicleOrderProps = TbVehicleOrderProps;
  constructor(
    private _fb: FormBuilder,
    private _dropdownService: DropdownService
  ) {}

  ngOnInit(): void {
    this._initializeFormConfig();
    this._defineFormGroup();
  }

  private _initializeFormConfig(){
    this.distributorDropdown = this._dropdownService.buildDropdownGenericTranslationList(
      this.vehicleOrderList,
      {
        labelProp: [TbVehicleOrderFilterProps.DISTRIBUTOR_CODE_NAME],
        valueProp: [TbVehicleOrderFilterProps.DISTRIBUTOR_CODE_NAME]
    });
    this.sourceLocationDropdown = this._dropdownService.buildDropdownGenericTranslationList(
      this.vehicleOrderList,
      {
        labelProp: [TbVehicleOrderFilterProps.SOURCE_LOCATION],
        valueProp: [TbVehicleOrderFilterProps.SOURCE_LOCATION]
    });
    this.orderNumberDropdown = this._dropdownService.buildDropdownGenericTranslationList(
      this.vehicleOrderList,
      {
        labelProp: [TbVehicleOrderFilterProps.ORDER_NUMBER],
        valueProp: [TbVehicleOrderFilterProps.ORDER_NUMBER]
    });
    this.accountStatusDropdown = this._dropdownService.buildDropdownGenericTranslationList(
      this.vehicleOrderList,
      {
        labelProp: [TbVehicleOrderFilterProps.ACCOUNT_STATUS],
        valueProp: [TbVehicleOrderFilterProps.ACCOUNT_STATUS]
    });
    this.operationalStatusDropdown = this._dropdownService.buildDropdownGenericTranslationList(
      this.vehicleOrderList,
      {
        labelProp: [TbVehicleOrderFilterProps.OPPER_STATUS],
        valueProp: [TbVehicleOrderFilterProps.OPPER_STATUS]
    });
    this.riskClassDrpdown = this._dropdownService.buildDropdownGenericTranslationList(
      this.vehicleOrderList,
      {
        labelProp: [TbVehicleOrderFilterProps.RISK_CLASS],
        valueProp: [TbVehicleOrderFilterProps.RISK_CLASS]
    });
  }

  private _defineFormGroup() {
    const groupValues = NextFormHelper.buildGroupValues(
      [
        TbVehicleOrderFilterProps.DISTRIBUTOR_CODE_NAME,
        TbVehicleOrderFilterProps.SOURCE_LOCATION,
        TbVehicleOrderFilterProps.ORDER_ID,
        TbVehicleOrderFilterProps.VEHICLE_MODEL,
        TbVehicleOrderFilterProps.ACCOUNT_STATUS,
        TbVehicleOrderFilterProps.OPPER_STATUS,
        TbVehicleOrderFilterProps.RISK_CLASS,
        TbVehicleOrderFilterProps.ORDER_DATE_FROM,
        TbVehicleOrderFilterProps.ORDER_DATE_TO
      ],
      {
        ...TbVehicleOrderConstant.extraFilterInitialValues,
        ...this.filterValues
      }
    );
    this.extraFormGroup = this._fb.group(groupValues);
  }
}
