import { Routes } from '@angular/router';
import { NextRouteProps } from '../../core/enums/route.enum';
import { TaskBoardComponent } from '@pages/task-board/task-board.component';
import { TaskBoardRouteConfig } from '@pages/task-board/task-board.route.config';
import { FormSectionProps } from '@components/organisms/layout/enum/form-section.enum';
import { TaskBoardConstant } from '@pages/task-board/constants/task-board.constant';
import { TaskBoardSection } from '@pages/task-board/enums/task-board.enum';
import { authGuardFn } from '../../shared/guards/auth.guard';
import { CountryComponent } from '@pages/general-maintenance/pages/country/country.component';
import { VehicleOrderConstant } from '@pages/new-business/pages/vehicle-order/constants/vehicle-order.constant';
import { VehicleOrderSection } from '@pages/new-business/pages/vehicle-order/enums/vehicle-order.enum';
import { VehicleOrderRouteConfig } from '@pages/new-business/pages/vehicle-order/vehicle-order.route.config';
import { dirtyFormGuard } from '@pages/distributor/guards/distributor-manager.guard';
import { DistributorRoutingConfig } from '@pages/distributor/distributor.routing.config';
import { VehicleOrderFormComponent } from '@pages/new-business/pages/vehicle-order/pages/vehicle-order-edition/components/vehicle-order-form/vehicle-order-form.component';

export class TaskBoardRouting {
  static routingConfig: Routes = [
    {
      path: '',
      children: [
        {
          path: '',
          data: {
            [NextRouteProps.ROUTE_ITEM_CONFIG]: TaskBoardRouteConfig.mainRoute
          },
          children: [
            {
              path: '',
              pathMatch: 'full',
              redirectTo: `${TaskBoardConstant.sectionConfig[TaskBoardSection.GENERAL_MAINTENANCE][FormSectionProps.PATH]}`
            },
            {
              path: TaskBoardRouteConfig.taskBoardTabStep[NextRouteProps.PATH],
              component: TaskBoardComponent,
              data: {
                [NextRouteProps.ROUTE_ITEM_CONFIG]: TaskBoardRouteConfig.taskBoardTabStep,
                [NextRouteProps.FORM_SECTION_CONFIG]: TaskBoardConstant.sectionConfig
              }
            }
          ]
        },
        {
          // Countries
          path: `${TaskBoardRouteConfig.generalMaintenance[NextRouteProps.PATH]}`,
          data: {
            [NextRouteProps.ROUTE_ITEM_CONFIG]: TaskBoardRouteConfig.generalMaintenance
          },
          children: [
            {
              path: TaskBoardRouteConfig.generalMaintenanceConfig[NextRouteProps.PATH],
              data: {
                [NextRouteProps.ROUTE_ITEM_CONFIG]: TaskBoardRouteConfig.generalMaintenanceConfig
              },
              children: [
                {
                  path: TaskBoardRouteConfig.generalMaintenanceViewDetails[NextRouteProps.PATH],
                  component: CountryComponent,
                  data: {
                    isTaskBoard: true,
                    [NextRouteProps.ROUTE_ITEM_CONFIG]: TaskBoardRouteConfig.generalMaintenanceViewDetails
                  }
                }
              ]
            }
          ]
        },

        {
          path: `${TaskBoardRouteConfig.distributors[NextRouteProps.PATH]}`, // Is defined again to preserve route hierarchy using same router outlet
          data: {
            isTaskBoard: true,
            [NextRouteProps.ROUTE_ITEM_CONFIG]: TaskBoardRouteConfig.distributors
          },
          children: [DistributorRoutingConfig.distributorRoute, DistributorRoutingConfig.distributorGroupRoute]
        },
        {
          path: `${TaskBoardRouteConfig.vehicleOrder[NextRouteProps.PATH]}`,
          data: {
            [NextRouteProps.ROUTE_ITEM_CONFIG]: TaskBoardRouteConfig.vehicleOrder
          },
          children: [
            {
              path: TaskBoardRouteConfig.vehicleOrderConfig[NextRouteProps.PATH],
              data: {
                [NextRouteProps.ROUTE_ITEM_CONFIG]: TaskBoardRouteConfig.vehicleOrderConfig
              },
              children: [
                {
                  path: TaskBoardRouteConfig.vehicleOrderEdit[NextRouteProps.PATH],
                  data: {
                    [NextRouteProps.ROUTE_ITEM_CONFIG]: TaskBoardRouteConfig.vehicleOrderEdit
                  },
                  children: [
                    {
                      path: '',
                      redirectTo: `${VehicleOrderConstant.formSectionConfig[VehicleOrderSection.GENERAL_INFORMATION][FormSectionProps.PATH]}`,
                      pathMatch: 'full'
                    },
                    {
                      path: VehicleOrderRouteConfig.vehicleOrderFormStep[NextRouteProps.PATH],
                      canDeactivate: [dirtyFormGuard],
                      component: VehicleOrderFormComponent,
                      data: {
                        [NextRouteProps.ROUTE_ITEM_CONFIG]: VehicleOrderRouteConfig.vehicleOrderFormStep,
                        [NextRouteProps.FORM_SECTION_CONFIG]: VehicleOrderConstant.formSectionConfig
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ];

  static entry = {
    path: TaskBoardRouteConfig.mainRoute.path,
    data: {
      [NextRouteProps.ROUTE_ITEM_CONFIG]: TaskBoardRouteConfig.mainRoute
    },
    loadChildren: () => import('./task-board.module').then(m => m.TaskBoardModule),
    canActivate: [authGuardFn]
  };
}
