import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { NextIcon } from '@components/atoms/visual/enums/icon.enum';
import { MaintenanceTask } from '@pages/task-board/types/task-board.type';
import { TabPageAbstractComponent } from '@components/organisms/tab-page-module/tab-page-abstract/tab-page-abstract.component';
import { TaskBoardConstant } from '@pages/task-board/constants/task-board.constant';
import { TbVehicleOrderItemList } from './types/tb-vehicle-order.type';
import { TaskBoardSection } from './enums/task-board.enum';

@Component({
  selector: 'task-board',
  templateUrl: './task-board.component.html',
  styleUrl: './task-board.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskBoardComponent extends TabPageAbstractComponent<TaskBoardSection> implements OnInit {
  distributorCount: number;
  generalMaintenanceCount: number;
  vehicleOrderCount: number;

  protected readonly NextIcon = NextIcon;

  constructor(
    injector$: Injector
  ) {
    super(injector$);
  }

  ngOnInit(): void {
    this._setEntityConfig();
    super.initialize();
  }

  getTotalItemsTab(items: any) {
    return this.countList(items);
  }

  private _setEntityConfig() {
    this.sectionList = TaskBoardConstant.formSectionList;
    this.sectionConfig = TaskBoardConstant.sectionConfig;
  }
}
