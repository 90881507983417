<next-action-header [labelKey]="'TASK_BOARD.TITLE'"></next-action-header>

<p-tabView [activeIndex]="sectionList.indexOf(activeSectionSig())" (activeIndexChange)="handleChangeTab($event)">
  <p-tabPanel>
    <ng-template pTemplate="header">
      <tab-title [icon]="NextIcon.MAINTENANCE" [title]="'TASK_BOARD.GENERAL_MAINTENANCE.TITLE' | translate"
                 [quantity]="generalMaintenanceCount"></tab-title>
    </ng-template>
    <tb-general-maintenance (generalMaintenance)="generalMaintenanceCount = getTotalItemsTab($event)"></tb-general-maintenance>
  </p-tabPanel>

  <p-tabPanel>
    <ng-template pTemplate="header">
      <tab-title [icon]="NextIcon.DISTRIBUTOR" [title]="'TASK_BOARD.DISTRIBUTORS.TITLE' | translate"
                 [quantity]="distributorCount"></tab-title>
    </ng-template>
    <tb-distributors (distributors)="distributorCount = getTotalItemsTab($event)"
    ></tb-distributors>
  </p-tabPanel>
  
  <p-tabPanel>
    <ng-template pTemplate="header">
      <tab-title [icon]="NextIcon.DISTRIBUTOR" [title]="'TASK_BOARD.VEHICLE_ORDER.TITLE' | translate"
                 [quantity]="vehicleOrderCount"></tab-title>
    </ng-template>
    <tb-vehicle-order (vehicleOrderTaskList)="vehicleOrderCount = getTotalItemsTab($event)"></tb-vehicle-order>
  </p-tabPanel>
</p-tabView>
