<tb-vehicle-order-filter
  [filterValues]="filterParams"
  [vehicleOrderList]="tableList"
  [selection]="selectedOrders"
  (filterChange)="handleFilterChange($event)"
  (modifyAction)="handleModifyVehicleOrder()"
  (cancelAction)="handleCancelVehicleOrderSelection()"
  (approveAction)="handleApproveVehicleOrderSelection()"
  [hasExtraFilter]="hasExtraFilter"
  [testIdDataAttr]="VehicleOrderTaskboardTestIds.FILTER_TOOLBAR"></tb-vehicle-order-filter>

<next-table
  *ngIf="tableList"
  [dataKey]="TbVehicleOrderProps.ID"
  [data]="tableList"
  [columns]="TbVehicleOrderConstant.tableListConfig"
  [filterValues]="filterParams"
  [sortField]="sortField"
  [sortOrder]="-1"
  [withSelection]="true"
  [selectedRows]="selectedOrders"
  (pageChange)="handlePaginationChange($event)"
  (selectionChange)="selectedOrders = $event"
  (sortPageChange)="sortPageChangeHandle($event)"
  [testIdDataAttr]="VehicleOrderTaskboardTestIds.FILTER_TOOLBAR"
  [totalRecords]="tableList.length"
>
    <ng-template pTemplate="columnTemplateRef" let-data="data" let-columnConfig="columnConfig">
    <div *ngIf="columnConfig[TableFieldProps.FIELD] === GenericTableColumnProps.ACTIONS"
      class="tb-general-maintenance__column">
      <ng-container [ngSwitch]="data[TbVehicleOrderProps.TASK_STATUS]">
        <ng-container *ngSwitchCase="TaskBoardStatus.WAITING_APPROVAL">
          <options-menu [options]="data[GenericTableColumnProps.MENU_LIST_WAITING]"></options-menu>
        </ng-container>
        <ng-container *ngSwitchCase="TaskBoardStatus.REJECTED">
          <options-menu [options]="data[GenericTableColumnProps.MENU_LIST_REJECTED]"></options-menu>
        </ng-container>
        <ng-container *ngSwitchCase="TaskBoardStatus.PENDING">
          <options-menu [options]="data[GenericTableColumnProps.MENU_LIST_PENDING]"></options-menu>
        </ng-container>
      </ng-container>
        <!-- <options-menu [options]="data[GenericTableColumnProps.MENU_LIST]"></options-menu> -->
    </div>
  </ng-template>
</next-table>
