<tb-distributors-filter
  [filterValues]="filterParams"
  (filterChange)="handleFilterChange($event)"
  [hasExtraFilter]="hasExtraFilter"
></tb-distributors-filter>
<next-table
  *ngIf="tableList"
  [dataKey]="MaintenanceTaskProps.ID"
  [data]="tableList"
  [columns]="TbDistributorsConstant.tableListConfig"
  [filterValues]="filterParams"
  [globalFilterFields]="TbDistributorsConstant.globalFilter"
  [sortField]="sortField"
  [sortOrder]="-1"
  (pageChange)="handlePaginationChange($event)"
  (sortPageChange)="sortPageChangeHandle($event)"
>
  <ng-template pTemplate="columnTemplateRef" let-data="data" let-columnConfig="columnConfig">
    <div
      *ngIf="columnConfig[TableFieldProps.FIELD] === GenericTableColumnProps.ACTIONS"
      class="tb-distributors__column"
    >
      <ng-container [ngSwitch]="data[MaintenanceTaskProps.STATUS]">
        <ng-container *ngSwitchCase="TaskBoardStatus.WAITING_APPROVAL">
          <link-button
            [iconType]="NextIcon.EYE_OPENED"
            [iconColor]="IconColor.BRAND_PRIMARY"
            [iconSize]="IconSizeModifier.REGULAR"
            (clickEvt)="handleView(data)"
          ></link-button>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <options-menu [options]="data[GenericTableColumnProps.MENU_LIST]"></options-menu>
        </ng-container>
      </ng-container>
    </div>
  </ng-template>
</next-table>
