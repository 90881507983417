import { ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { FilterBaseComponent } from '@components/organisms/next-table/components/filter-base/filter-base.component';
import { TbDistributorsConstant } from '@pages/task-board/constants/tb-distributors.constant';
import { GenericTableColumnProps, TableFieldProps } from '@components/organisms/next-table/enums/next-table.enum';
import { TemplateType } from '@components/organisms/next-table/next-table.type';
import { MaintenanceTaskProps, TaskBoardRequestType, TaskBoardStatus } from '@pages/task-board/enums/task-board.enum';
import { IconColor, IconSizeModifier, NextIcon } from '@components/atoms/visual/enums/icon.enum';
import { LinkButtonPosition } from '@components/atoms/buttons/components/link-button/link-button.enum';
import { NavigationService } from '@services/navigation.service';
import { TbDistributorAction } from '@pages/task-board/enums/tb-distributors.enum';
import { MenuItemService } from '../../../../core/services/menu-item.service';
import { DistributorManagerProps } from '@pages/distributor/enums/distributor-manager.enum';
import { ConfirmServiceService } from '@components/organisms/confirm-dialog/services/confirm-service.service';
import { ConfirmDialogProps } from '@components/organisms/confirm-dialog/confirm-dialog.enum';
import { DistributorManagerConstant } from '@pages/distributor/constant/distributor-manager.constant';
import { MaintenanceTask } from '@pages/task-board/types/task-board.type';
import { RouteParams } from '../../../../core/enums/route.enum';
import { NavigationConfigProps } from '../../../../shared/enums/navigation.enum';
import { TaskBoardRouteConfig } from '@pages/task-board/task-board.route.config';
import { TaskBoardService } from '@pages/task-board/services/task-board.service';

@Component({
  selector: 'tb-distributors',
  templateUrl: './tb-distributors.component.html',
  styleUrl: './tb-distributors.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TbDistributorsComponent extends FilterBaseComponent implements OnInit {
  @Output() distributors = new EventEmitter<any>();

  distributorTaskList: MaintenanceTask[];
  tableList: MaintenanceTask[];
  sortField = DistributorManagerProps.CREATED_TIMESTAMP

  protected readonly TbDistributorsConstant = TbDistributorsConstant;
  protected readonly GenericTableColumnProps = GenericTableColumnProps;
  protected readonly TemplateType = TemplateType;
  protected readonly TableFieldProps = TableFieldProps;
  protected readonly TaskBoardStatus = TaskBoardStatus;
  protected readonly IconColor = IconColor;
  protected readonly NextIcon = NextIcon;
  protected readonly IconSizeModifier = IconSizeModifier;
  protected readonly LinkButtonPosition = LinkButtonPosition;
  protected readonly DistributorManagerProps = DistributorManagerProps;
  protected readonly DistributorManagerConstant = DistributorManagerConstant;
  protected readonly MaintenanceTaskProps = MaintenanceTaskProps;

  constructor(
    injector$: Injector,
    private _menuItemService: MenuItemService,
    private _navigationService: NavigationService,
    private _confirmationService: ConfirmServiceService,
    private _taskBoardService: TaskBoardService
  ) {
    super(injector$);
  }

  ngOnInit(): void {
    this.applyFilter();
    this._taskBoardService.get().pipe().subscribe(list => {
      this.distributorTaskList = list.filter((item: any) => item.type !== TaskBoardRequestType.COUNTRY_RATING_UPDATE);
      this.distributors.emit(this.distributorTaskList);
      this._configActionList();
    });
  }

  sortPageChangeHandle(event: any) {
    // TODO Implement
  }

  handleView(maintenanceTask: MaintenanceTask) {
    this._navigationService
      .navigateToRouteConfig(TbDistributorsConstant.viewDetailsByEntity[maintenanceTask[MaintenanceTaskProps.TYPE]], {
        urlParams: { [RouteParams.ID]: maintenanceTask[MaintenanceTaskProps.ID] },
        [NavigationConfigProps.ROUTE_PARENT]: TaskBoardRouteConfig.distributors
      })
      .then();
  }

  handleModifyTask(maintenanceTask: MaintenanceTask) {
    this._navigationService
      .navigateToRouteConfig(
        TbDistributorsConstant.taskBoardEditionRedirectionByEntity[maintenanceTask[MaintenanceTaskProps.TYPE]],
        {
          urlParams: { [RouteParams.ID]: maintenanceTask[MaintenanceTaskProps.ID] + 'MTID' },
          [NavigationConfigProps.ROUTE_PARENT]: TaskBoardRouteConfig.distributors
        }
      )
      .then();
  }

  private _configActionList() {
    this.tableList = this.distributorTaskList.map((maintenanceTask: MaintenanceTask) => {
      return {
        ...maintenanceTask,
        [GenericTableColumnProps.MENU_LIST]: this._menuItemService.buildMenuItemList(
          [TbDistributorAction.MODIFY, TbDistributorAction.DELETE],
          {
            [TbDistributorAction.MODIFY]: {
              ...TbDistributorsConstant.actionConfig[TbDistributorAction.MODIFY],
              callback: () => this._modifyActionCallback(maintenanceTask)
            },
            [TbDistributorAction.DELETE]: {
              ...TbDistributorsConstant.actionConfig[TbDistributorAction.DELETE],
              callback: () => this.deleteActionCallback(maintenanceTask)
            }
          }
        )
      };
    });
  }

  private deleteActionCallback(maintenanceTask: MaintenanceTask) {
    this._confirmationService
      .asyncConfirmation({
        [ConfirmDialogProps.TITLE_KEY]: 'CONFIRMATION.DELETE_TITLE',
        [ConfirmDialogProps.SUMMARY_KEY]: 'CONFIRMATION.DELETE_SUMMARY',
        [ConfirmDialogProps.CONFIRM_LABEL_KEY]: 'CONFIRMATION.DELETE_BUTTON',
        [ConfirmDialogProps.CONFIRM_CALLBACK]: () => this._handleDelete(maintenanceTask),
        [ConfirmDialogProps.HIDE_DISMISS]: true
      })
      .then();
  }

  private _modifyActionCallback(maintenanceTask: MaintenanceTask) {
    this.handleModifyTask(maintenanceTask);
  }

  private _handleDelete(maintenanceTask: MaintenanceTask) {
    // TODO
    console.log('Deleted', maintenanceTask);
  }
}
