import { ColumnSize, TableField, TemplateType } from '@components/organisms/next-table/next-table.type';
import { TbDistributorAction } from '@pages/task-board/enums/tb-distributors.enum';
import {
  GenericTableColumnProps,
  NextFilterType,
  TableFieldProps
} from '@components/organisms/next-table/enums/next-table.enum';
import { FilterToggleControlConfig } from '@components/organisms/next-table-filter/types/next-table-filter.type';
import { FilterToggleProps } from '@components/organisms/next-table-filter/enums/next-table.filter.enum';
import { ColorType } from '../../../shared/enums/color.enum';
import { GenericObject } from '@utils/models/Types';
import { MaintenanceTaskProps, TaskBoardStatus } from '@pages/task-board/enums/task-board.enum';
import { DistributorManagerRouteConfig } from '@pages/distributor/distributor-manager.route.config';
import { DistributorManagerType } from '@pages/distributor/enums/distributor-manager.enum';
import { NextIcon } from '@components/atoms/visual/enums/icon.enum';
import { TaskBoardConstant } from '@pages/task-board/constants/task-board.constant';
import { NextFormatDatePipe } from '@pipes/common/custom-date.pipe';

export class TbDistributorsConstant {
  static tableListConfig: TableField[] = [
    {
      [TableFieldProps.FIELD]: MaintenanceTaskProps.CREATED_TIMESTAMP,
      [TableFieldProps.HEADER]: 'TASK_BOARD.DISTRIBUTORS.REQUEST_CREATION_DATE',
      [TableFieldProps.PIPE_LIST]: [{ pipe: NextFormatDatePipe }],
      [TableFieldProps.IS_FILTER]: true
    },
    {
      [TableFieldProps.FIELD]: [MaintenanceTaskProps.AFFECTED_RESOURCE_ID, MaintenanceTaskProps.AFFECTED_RESOURCE_NAME],
      [TableFieldProps.SEPARATOR]: ' - ',
      [TableFieldProps.HEADER]: 'TASK_BOARD.DISTRIBUTORS.ENTITY_CODE_NAME',
      [TableFieldProps.IS_FILTER]: true
    },
    {
      [TableFieldProps.FIELD]: MaintenanceTaskProps.LATEST_EXECUTED_STEP,
      [TableFieldProps.HEADER]: 'TASK_BOARD.DISTRIBUTORS.REQUEST_TYPE',
      [TableFieldProps.IS_FILTER]: true
    },
    {
      [TableFieldProps.FIELD]: MaintenanceTaskProps.INITIATOR_CDSID,
      [TableFieldProps.HEADER]: 'TASK_BOARD.DISTRIBUTORS.REQUESTOR'
    },
    {
      [TableFieldProps.FIELD]: MaintenanceTaskProps.OWNER_CDSID,
      [TableFieldProps.HEADER]: 'TASK_BOARD.DISTRIBUTORS.LAST_APPROVER'
    },
    {
      [TableFieldProps.FIELD]: MaintenanceTaskProps.STATUS,
      [TableFieldProps.HEADER]: 'TASK_BOARD.DISTRIBUTORS.TASK_STATUS',
      [TableFieldProps.IS_FILTER]: true,
      [TableFieldProps.FILTER_TYPE]: NextFilterType.MULTI_SELECTION,
      [TableFieldProps.TEMPLATE_TYPE]: TemplateType.STATUS,
      [TableFieldProps.STATUS_MAP]: TaskBoardConstant.taskStatusConfig,
      [TableFieldProps.WIDTH]: ColumnSize.MEDIUM
    },
    {
      [TableFieldProps.FIELD]: GenericTableColumnProps.ACTIONS,
      [TableFieldProps.HEADER]: 'TABLE.FIELDS.ACTIONS',
      [TableFieldProps.TEMPLATE_TYPE]: TemplateType.COLUMN_TEMPLATE
    }
  ];
  static filterToggleConfig: FilterToggleControlConfig[] = [
    {
      [FilterToggleProps.CONTROL_KEY]: TaskBoardStatus.WAITING_APPROVAL,
      [FilterToggleProps.LABEL_KEY]: 'TASK_BOARD.STATUS.WAITING_APPROVAL',
      [FilterToggleProps.COLOR_TYPE]: ColorType.CAUTION
    },
    {
      [FilterToggleProps.CONTROL_KEY]: TaskBoardStatus.PENDING,
      [FilterToggleProps.LABEL_KEY]: 'TASK_BOARD.STATUS.PENDING',
      [FilterToggleProps.COLOR_TYPE]: ColorType.PURPLE
    },
    {
      [FilterToggleProps.CONTROL_KEY]: TaskBoardStatus.REJECTED,
      [FilterToggleProps.LABEL_KEY]: 'TASK_BOARD.STATUS.REJECTED',
      [FilterToggleProps.COLOR_TYPE]: ColorType.DANGER
    }
  ];

  static globalFilter = [MaintenanceTaskProps.ID, MaintenanceTaskProps.NAME];
  static extraFilterInitialValues: GenericObject = {};

  static viewDetailsByEntity: GenericObject = {
    [DistributorManagerType.DISTRIBUTOR]: DistributorManagerRouteConfig.distributorDetail,
    [DistributorManagerType.GROUP]: DistributorManagerRouteConfig.groupDetail,
    CREATE_DIST_GRL_INFO: DistributorManagerRouteConfig.distributorDetail // TODO Use proper types
  };

  static taskBoardEditionRedirectionByEntity: GenericObject = {
    [DistributorManagerType.DISTRIBUTOR]: DistributorManagerRouteConfig.distributorFormId,
    [DistributorManagerType.GROUP]: DistributorManagerRouteConfig.groupFormId,
    CREATE_DIST_GRL_INFO: DistributorManagerRouteConfig.distributorFormId
  };

  static actionConfig = {
    [TbDistributorAction.MODIFY]: {
      label: 'ACTIONS.MODIFY',
      icon: NextIcon.EDIT
    },
    [TbDistributorAction.DELETE]: {
      label: 'ACTIONS.DELETE',
      icon: NextIcon.DELETE
    }
  };
}
