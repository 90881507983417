import { GenericTableColumnProps, NextFilterType, TableFieldProps } from '@components/organisms/next-table/enums/next-table.enum';
import { ColumnSize, FieldType, TableField, TemplateType } from '@components/organisms/next-table/next-table.type';
import { TbVehicleOrderAction, TbVehicleOrderFilterProps, TbVehicleOrderProps } from '../enums/tb-vehicle-order.enum';
import { TaskBoardConstant } from './task-board.constant';
import { NextIcon } from '@components/atoms/visual/enums/icon.enum';
import { FilterToggleControlConfig } from '@components/organisms/next-table-filter/types/next-table-filter.type';
import { TaskBoardStatus } from '../enums/task-board.enum';
import { FilterToggleProps } from '@components/organisms/next-table-filter/enums/next-table.filter.enum';
import { ColorType } from 'app/shared/enums/color.enum';
import { GenericObject } from '@utils/models/Types';
import { NextFormatCurrencyPipe } from '@pipes/next-format-currency.pipe';

export class TbVehicleOrderConstant {
  static tableListConfig: TableField[] = [
    {
      [TableFieldProps.FIELD]: TbVehicleOrderProps.ORDER_DATE,
      [TableFieldProps.HEADER]: 'TASK_BOARD.VEHICLE_ORDER.ORDER_DATE',
      [TableFieldProps.IS_FILTER]: true,
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.FIELD_TYPE]: [FieldType.DATE],
      [TableFieldProps.FILTER_TYPE]: NextFilterType.DATE
    },
    {
      [TableFieldProps.FIELD]: TbVehicleOrderProps.ORDER_ID,
      [TableFieldProps.HEADER]: 'TASK_BOARD.VEHICLE_ORDER.ORDER_NUMBER',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.IS_FILTER]: true
    },
    {
      [TableFieldProps.FIELD]: [TbVehicleOrderProps.DISTRIBUTOR_ID, TbVehicleOrderProps.DISTRIBUTOR_NAME],
      [TableFieldProps.SEPARATOR]: ' - ',
      [TableFieldProps.HEADER]: 'TASK_BOARD.VEHICLE_ORDER.DISTRIBUTOR_CODE_NAME',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.IS_FILTER]: true
    },
    {
      [TableFieldProps.FIELD]: TbVehicleOrderProps.SOURCE_LOCATION,
      [TableFieldProps.HEADER]: 'TASK_BOARD.VEHICLE_ORDER.SOURCE_LOCATION',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.IS_FILTER]: true
    },
    {
      [TableFieldProps.FIELD]: TbVehicleOrderProps.VEHICLE_MODEL,
      [TableFieldProps.HEADER]: 'TASK_BOARD.VEHICLE_ORDER.VEHICLE_MODEL',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.IS_FILTER]: true
    },
    {
      [TableFieldProps.FIELD]: TbVehicleOrderProps.ORDER_AMOUNT,
      [TableFieldProps.HEADER]: 'TASK_BOARD.VEHICLE_ORDER.ORDER_VALUE',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.IS_FILTER]: true,
      [TableFieldProps.PIPE_LIST]: [
        {
          pipe: NextFormatCurrencyPipe,
          args: [{ fromValue: TbVehicleOrderProps.ORDER_CURRENCY }]
        }
      ]
    },
    {
      [TableFieldProps.FIELD]: TbVehicleOrderProps.ACCOUNT_STATUS,
      [TableFieldProps.HEADER]: 'TASK_BOARD.VEHICLE_ORDER.ACCOUNT_STATUS',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.IS_FILTER]: true
    },
    {
      [TableFieldProps.FIELD]: TbVehicleOrderProps.OPER_STATUS,
      [TableFieldProps.HEADER]: 'TASK_BOARD.VEHICLE_ORDER.OPER_STATUS',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.IS_FILTER]: true
    },
    {
      [TableFieldProps.FIELD]: TbVehicleOrderProps.RISK_CLASS,
      [TableFieldProps.HEADER]: 'TASK_BOARD.VEHICLE_ORDER.RISK_CLASSIFICATION',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.IS_FILTER]: true
    },
    {
      [TableFieldProps.FIELD]: TbVehicleOrderProps.TASK_STATUS,
      [TableFieldProps.HEADER]: 'TASK_BOARD.VEHICLE_ORDER.TASK_STATUS',
      [TableFieldProps.TEMPLATE_TYPE]: TemplateType.STATUS,
      [TableFieldProps.STATUS_MAP]: TaskBoardConstant.taskStatusConfig,
      [TableFieldProps.WIDTH]: ColumnSize.MEDIUM,
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.IS_FILTER]: true,
      [TableFieldProps.FILTER_TYPE]: NextFilterType.MULTI_SELECTION
    },
    {
      [TableFieldProps.FIELD]: GenericTableColumnProps.ACTIONS,
      [TableFieldProps.HEADER]: 'TABLE.FIELDS.ACTIONS',
      [TableFieldProps.TEMPLATE_TYPE]: TemplateType.COLUMN_TEMPLATE,
      [TableFieldProps.WIDTH]: ColumnSize.VERY_SMALL
    }
  ];

  static actionConfig = {
    [TbVehicleOrderAction.MODIFY]: {
      label: 'ACTIONS.MODIFY',
      icon: NextIcon.MODIFY
    },
    [TbVehicleOrderAction.DELETE]: {
      label: 'ACTIONS.DELETE',
      icon: NextIcon.DELETE
    },
    [TbVehicleOrderAction.REJECT]: {
      label: 'ACTIONS.REJECT',
      icon: NextIcon.REJECT
    },
    [TbVehicleOrderAction.APPROVE]: {
      label: 'ACTIONS.APPROVE',
      icon: NextIcon.APPROVE
    },
    [TbVehicleOrderAction.EXCEPTION]: {
      label: 'ACTIONS.EXCEPTION',
      icon: NextIcon.EXCEPTION
    }
  };
  static extraFilterInitialValues: GenericObject = {
    [TbVehicleOrderFilterProps.ORDER_DATE_FROM]: '',
    [TbVehicleOrderFilterProps.ORDER_DATE_TO]: '',
    [TbVehicleOrderFilterProps.DISTRIBUTOR_CODE_NAME]: '',
    [TbVehicleOrderFilterProps.SOURCE_LOCATION]: '',
    [TbVehicleOrderFilterProps.VEHICLE_MODEL]: '',
    [TbVehicleOrderFilterProps.ACCOUNT_STATUS]: '',
    [TbVehicleOrderFilterProps.ORDER_NUMBER]: '',
    [TbVehicleOrderFilterProps.OPPER_STATUS]: '',
    [TbVehicleOrderFilterProps.RISK_CLASS]: ''
  };

  static filterToggleConfig: FilterToggleControlConfig[] = [
    {
      [FilterToggleProps.CONTROL_KEY]: TaskBoardStatus.WAITING_APPROVAL,
      [FilterToggleProps.LABEL_KEY]: 'TASK_BOARD.STATUS.WAITING_APPROVAL',
      [FilterToggleProps.COLOR_TYPE]: ColorType.CAUTION
    },
    {
      [FilterToggleProps.CONTROL_KEY]: TaskBoardStatus.REJECTED,
      [FilterToggleProps.LABEL_KEY]: 'TASK_BOARD.STATUS.REJECTED',
      [FilterToggleProps.COLOR_TYPE]: ColorType.DANGER
    },
    {
      [FilterToggleProps.CONTROL_KEY]: TaskBoardStatus.PENDING,
      [FilterToggleProps.LABEL_KEY]: 'TASK_BOARD.STATUS.PENDING',
      [FilterToggleProps.COLOR_TYPE]: ColorType.PURPLE
    }
  ];
}
