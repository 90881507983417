<next-table-filter [toggleConfig]="TbGeneralMaintenanceConstant.filterToggleConfig"
                   [toggleTargetColumn]="TbGeneralMaintenanceProps.STATUS" [extraFormGroup]="extraFormGroup"
                   [extraFormGroupInitialValues]="TbGeneralMaintenanceConstant.extraFilterInitialValues"
                   [filterValues]="filterValues"
                   (filterChange)="filterChange.emit($event)"
                   [searchFilter]="false"
                   [hasExtraFilter]="hasExtraFilter">
    <div extraFilterContent class="tb-general-maintenance-filter__form">
        <input-container
            [labelKey]="'TASK_BOARD.DISTRIBUTORS.CREATION_DATE'"
            class="tb-general-maintenance-filter__item"
        >
            <date-from-to [fromControl]="extraFormGroup | deepControl: [TbGeneralMaintenanceFilterProps.CREATION_DATE_FROM]"
                        [toControl]="extraFormGroup | deepControl: [TbGeneralMaintenanceFilterProps.CREATION_DATE_TO]" [testIdDataAttr]="TbGeneralMaintenanceFilterProps.CREATION_DATE_FROM"
                        inputElement></date-from-to>
        </input-container>
        <input-container
            *ngIf="extraFormGroup | deepControl: [TbGeneralMaintenanceFilterProps.REQUEST_TYPE] as requestTypeControl"
            [control]="requestTypeControl"
            [labelKey]="'TASK_BOARD.DISTRIBUTORS.REQUEST_TYPE'"
            class="tb-general-maintenance-filter__item"
        >
            <next-dropdown
            [control]="requestTypeControl"
            [itemsObs$]="requestTypeList$"
            [name]="TbGeneralMaintenanceFilterProps.REQUEST_TYPE" [testIdDataAttr]="TbGeneralMaintenanceFilterProps.REQUEST_TYPE" inputElement></next-dropdown>
        </input-container>
    </div>
</next-table-filter>
