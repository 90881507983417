<next-table-filter [toggleConfig]="TbDistributorsConstant.filterToggleConfig"
                   [toggleTargetColumn]="MaintenanceTaskProps.STATUS" [extraFormGroup]="extraFormGroup"
                   [extraFormGroupInitialValues]="TbDistributorsConstant.extraFilterInitialValues"
                   [filterValues]="filterValues"
                   (filterChange)="filterChange.emit($event)"
                   [hasExtraFilter]="hasExtraFilter"
>
  <div [formGroup]="extraFormGroup" extraFilterContent class="tb-distributors-filter__form">
    <input-container
      [labelKey]="'TASK_BOARD.DISTRIBUTORS.CREATION_DATE'"
      class="tb-distributors-filter__item"
    >
      <date-from-to [fromControl]="extraFormGroup | deepControl: [TbDistributorFilterProps.CREATION_DATE_FROM]"
                    [toControl]="extraFormGroup | deepControl: [TbDistributorFilterProps.CREATION_DATE_TO]"
                    inputElement></date-from-to>
    </input-container>
    <input-container
      *ngIf="extraFormGroup | deepControl: [TbDistributorFilterProps.REQUEST_TYPE] as requestTypeControl"
      [control]="requestTypeControl"
      [labelKey]="'TASK_BOARD.DISTRIBUTORS.REQUEST_TYPE'"
      class="tb-distributors-filter__item"
    >
      <next-dropdown
        [control]="requestTypeControl"
        [itemsObs$]="requestTypeList$"
        [name]="TbDistributorFilterProps.REQUEST_TYPE" inputElement></next-dropdown>
    </input-container>
  </div>
</next-table-filter>
