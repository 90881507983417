import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { IconColor, IconSizeModifier, NextIcon } from '@components/atoms/visual/enums/icon.enum';
import { ConfirmDialogProps } from '@components/organisms/confirm-dialog/confirm-dialog.enum';
import { ConfirmServiceService } from '@components/organisms/confirm-dialog/services/confirm-service.service';
import { FilterBaseComponent } from '@components/organisms/next-table/components/filter-base/filter-base.component';
import { GenericTableColumnProps, TableFieldProps } from '@components/organisms/next-table/enums/next-table.enum';
import { CountryService } from '@pages/general-maintenance/pages/country/services/country.service';
import { TbGeneralMaintenanceConstant } from '@pages/task-board/constants/tb-general-maintenance.constant';
import { MaintenanceTaskProps, TaskBoardRequestType, TaskBoardSection, TaskBoardStatus } from '@pages/task-board/enums/task-board.enum';
import { TbGeneralMaintenanceAction, TbGeneralMaintenanceProps } from '@pages/task-board/enums/tb-general-maintenance.enum';
import { TaskBoardService } from '@pages/task-board/services/task-board.service';
import { TaskBoardRouteConfig } from '@pages/task-board/task-board.route.config';
import { MaintenanceTask } from '@pages/task-board/types/task-board.type';
import { TbGeneralMaintenanceItemList } from '@pages/task-board/types/tb-general-maintenance.type';
import { NavigationService } from '@services/navigation.service';
import { ToastMsgService } from '@services/toast-msg.service';
import { RouteParams } from 'app/core/enums/route.enum';
import { MenuItemService } from 'app/core/services/menu-item.service';

@Component({
  selector: 'tb-general-maintenance',
  templateUrl: './tb-general-maintenance.component.html',
  styleUrl: './tb-general-maintenance.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TbGeneralMaintenanceComponent extends FilterBaseComponent implements OnInit {
  @Output() generalMaintenance = new EventEmitter<any>();

  generalMaintenanceList: MaintenanceTask[];
  tableList: MaintenanceTask[];
  activePopup:boolean = false;
  selectedCountry: any;
  countriesRiskRatingDropdown: any = [];
  sortField = TbGeneralMaintenanceProps.CREATED_TIMESTAMP;
  section = TaskBoardSection.GENERAL_MAINTENANCE;
  visible = true;
  currency: string;

  protected readonly TbGeneralMaintenanceProps = TbGeneralMaintenanceProps;
  protected readonly TbGeneralMaintenanceConstant = TbGeneralMaintenanceConstant;
  protected readonly TableFieldProps = TableFieldProps;
  protected readonly GenericTableColumnProps = GenericTableColumnProps;
  protected readonly TaskBoardStatus = TaskBoardStatus;
  protected readonly IconColor = IconColor;
  protected readonly NextIcon = NextIcon;
  protected readonly IconSizeModifier = IconSizeModifier;
  
  constructor(
    injector$: Injector,
    private _menuItemService: MenuItemService,
    private _confirmationService: ConfirmServiceService,
    private _navigationService: NavigationService,
    private _countryService: CountryService,
    private _cdr: ChangeDetectorRef,
    private _taskBoardService: TaskBoardService,
    private _toastService: ToastMsgService
  ) {
    super(injector$);
    this._cdr = injector$.get(ChangeDetectorRef);
  }

  async ngOnInit(): Promise<void> {
    this.applyFilter();
    this._taskBoardService.get().pipe().subscribe(list => {
      this.generalMaintenanceList = list.filter((item: any) => item.type === TaskBoardRequestType.COUNTRY_RATING_UPDATE);
      this.generalMaintenance.emit(this.generalMaintenanceList);
      this._configActionList();
    });

    this._countryService.getRiskRatingCountries().subscribe(result => {
      this.countriesRiskRatingDropdown = this._countryService.createDropdownRiskRatingList(result);
    });

    
  }

  sortPageChangeHandle(event: any) {
    // TODO Implement
  }

  handleView(tbGeneralMaintenance: TbGeneralMaintenanceItemList) {
    this._navigationService
      .navigateToRouteConfig(
        TaskBoardRouteConfig.generalMaintenanceViewDetails,
        {
          urlParams: { [RouteParams.ID]: tbGeneralMaintenance[MaintenanceTaskProps.ID] }
        }
      )
      .then();
  }

  dismissPopup() {
    this.selectedCountry = [];
    this.activePopup = false;
  }
  
  updateCountry(country: any) {
    this._countryService.updateCountry(country.form.code, country.form, country.selectedCountry).subscribe({
      next: (response) => {
        const index = this.tableList.findIndex(task => task.id === response.id);
        
        const country = this.tableList[index];
        
        if (index >= 0) {
          this.tableList[index].status = TaskBoardStatus.WAITING_APPROVAL;
          this.tableList.splice(index, 1);
          this.tableList.unshift(country)
        }
        this._cdr.markForCheck();
        this.selectedCountry = [];
        this.activePopup = false;
      },
      error: () => {
        this._toastService.error('TASK_BOARD.CONFIRMATION.ERROR_UPDATED');
      }
    })
  }

  private _configActionList() {
    this.tableList = this.generalMaintenanceList.map((tdGeneralMaintenance: MaintenanceTask) => {
      return {
        ...tdGeneralMaintenance,
        [GenericTableColumnProps.MENU_LIST]: this._menuItemService.buildMenuItemList(
          [TbGeneralMaintenanceAction.MODIFY, TbGeneralMaintenanceAction.DELETE],
          {
            [TbGeneralMaintenanceAction.MODIFY]: {
              ...TbGeneralMaintenanceConstant.actionConfig[TbGeneralMaintenanceAction.MODIFY],
              callback: () => this.viewPopup(tdGeneralMaintenance)
            },
            [TbGeneralMaintenanceAction.DELETE]: {
              ...TbGeneralMaintenanceConstant.actionConfig[TbGeneralMaintenanceAction.DELETE],
              callback: () => this.deleteActionCallback(tdGeneralMaintenance)
            }
          }
        )
      };
    });
  }

  viewPopup(tbGeneralMaintenance: MaintenanceTask) {
    this._countryService.getCountryById(tbGeneralMaintenance.affectedResourceId).subscribe((response) => {
      const jsonPayload = JSON.parse(response.maintenanceTask.jsonPayload);
      this.selectedCountry = {
        name: response.name,
        code: response.code,
        riskCode: jsonPayload.countryRisk,
        insuranceExceptionAmount: jsonPayload.insuranceLimitExAmt,
        insuranceExceptionEffectiveTimestamp: jsonPayload.insuranceLimitExEffortTimeStamp,
        insuranceExceptionExpiryTimestamp: jsonPayload.insuranceLimitExExpiryTimeStamp,
        maintenanceTask: response.maintenanceTask
      }
      this.currency = response.insuranceExceptionCurrency;
      this.activePopup = true;
      this._cdr.markForCheck();
    });
  }

  private deleteActionCallback(tbGeneralMaintenance: MaintenanceTask) {
    
    this._confirmationService
      .asyncConfirmation({
        [ConfirmDialogProps.TITLE_KEY]: 'CONFIRMATION.DELETE_TITLE',
        [ConfirmDialogProps.SUMMARY_KEY]: 'CONFIRMATION.DELETE_SUMMARY',
        [ConfirmDialogProps.CONFIRM_LABEL_KEY]: 'CONFIRMATION.DELETE_BUTTON',
        [ConfirmDialogProps.CONFIRM_CALLBACK]: () => this._handleDelete(tbGeneralMaintenance),
        [ConfirmDialogProps.HIDE_DISMISS]: true
      })
      .then();
  }

  private _handleDelete(tbGeneralMaintenance: MaintenanceTask) {
    this.visible = false;
    this._taskBoardService.deleteTask(this.section,tbGeneralMaintenance.affectedResourceId, tbGeneralMaintenance.id).subscribe({
      next: () => {
        this._toastService.success('TASK_BOARD.CONFIRMATION.DELETED', {
          id: tbGeneralMaintenance.id
        });
        
        this.tableList = this.tableList.filter(item => item.id !== tbGeneralMaintenance.id)
        
        this.selectedCountry = [];
        this._cdr.detectChanges();
      },
      error: () => {
        this._toastService.error('TASK_BOARD.CONFIRMATION.ERROR_DELETED');
      }
    })
   }
}
